import React from "react"
import { graphql } from "gatsby"
import EpisodeRow from "../components/episode-row"
import PageTitle from "../components/global/page-title"
import Layout from "../components/layout"
import Seo from "../components/seo"
import { StationContext } from "../context/station-context"
import moment from "moment"
import "moment-timezone"

function convertUTCToTimezone(
  utcDt,
  utcDtFormat,
  timezone,
  targetFormat = "dddd, MMMM Do YYYY, h:mm:ss a"
) {
  return moment.utc(utcDt, utcDtFormat).tz(timezone).format(targetFormat)
}

const OndemandIndex = ({ data, location }) => {
  const {station } = React.useContext(StationContext);
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const episodes = data.allEpisode.edges
  const [actualEpisodes, setActualEpisodes] = React.useState([])

  React.useEffect(() => {
    const newEpisodes = episodes.filter(item => {
      const startStream = convertUTCToTimezone(
        item.node.show_start,
        null,
        station.timezone,
        ""
      )
      console.log("episode", item)
      if (moment().isAfter(startStream) && item.node.timestamp >= parseInt(process.env.GATSBY_EPISODE_START)) {
        return item
      }
    })
    setActualEpisodes(newEpisodes)
  }, [episodes, setActualEpisodes])

  // console.log("episodes", episodes)
  if (episodes.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <Seo title="All posts" />
        <div>No Episodes found :(</div>>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="All episodes" />
      <PageTitle title={'Ondemand'} />
      {actualEpisodes.length > 0 &&
        actualEpisodes.map((episode, i) => (
          <EpisodeRow episode={episode} key={`${i}-${episode.node.id}`} />
        ))}
    </Layout>
  )
}

export default OndemandIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allEpisode(sort: { fields: show_start, order: DESC }) {
      edges {
        node {
          ...Episode
        }
      }
    }
  }
`
